<template>
	<div class="content-wrap">
		<!-- 伦理审查 -->
		<Spin fix v-if="loading"></Spin>
    <div class="search_box">
      <CtmsSearchBox class="search_left" @onSearch="searchHandle" @onReset="reset">
        <CtmsSearchBoxItem :span="1" label="首笔款入账日期">
          <DatePicker
            type="daterange"
            placeholder="请选择"
            :transfer="true"
            style="width: 100%"
            format="yyyy-MM-dd"
            :value="times"
            @on-change="v => (times = v)"
          ></DatePicker>
        </CtmsSearchBoxItem>
        <CtmsSearchBoxItem :span="1" label="项目类型">
          <Select
            v-model="searchParams.projectTypeId"
            :transfer="true"
            placeholder="请选择类型"
            @on-change="handleProjectTypeChange"
          >
            <Option :value="item.id" v-for="item in projTypeList" :key="item.id">{{item.typeName}}</Option>
          </Select>
        </CtmsSearchBoxItem>
        <CtmsSearchBoxItem :span="1" label="IIT临床研究类型" v-if="isIITProject">
          <Select
            v-model="searchParams.iitType"
            :transfer="true"
            placeholder="请选择IIT临床研究类型"
          >
            <Option value="研究者发起IIT">研究者发起IIT</Option>
            <Option value="企业发起的IIT">企业发起的IIT</Option>
          </Select>
        </CtmsSearchBoxItem>
      </CtmsSearchBox>
    </div>
		<CtmsAction>
          <Tab currentName="second"/>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="data"
      		:page="page"
			ref="table"
		>
		</CtmsDataGrid>
	</div>
</template>

<script>

import Tab from "./components/InstitutionalFundingTab"
import { mapState } from "vuex"

import api from "@/api/report/report"
const { apiGetOutlayFirstListPage } = api
import projectApi from "@/api/project/project"
const { apiGetProjTypeList } = projectApi

export default {
	name: "WardCheckIn",
  components: {
    Tab
  },
	data() {
		return {
			loading: false,
      projTypeList: [],
			times: [],
			isIITProject: false,
			searchParams: {
        		projectTypeId: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : "",
				iitType: ""
			},
			oldSearchParams: {
        		projectTypeId: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : "",
				iitType: ""
			},
			data: [],
			page: {
        		pageNum: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		}),
		columns() {
			return [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 400
				},
				{
					title: "项目类型",
					key: "projectType",
					minWidth: 100,
				},
				{
					title: "申办方/资助方",
					key: this.getSponsorKey(),
					minWidth: 100
				},
				{
					title: "主要研究者(PI)",
					key: "piName",
					minWidth: 100
				},
				{
					title: "首笔到账金额(元)",
					key: "firstReceivedPayAmount",
					minWidth: 100
				},
				{
					title: "到账时间",
					key: "receivedPayDt",
					minWidth: 100
				},
				{
					title: "累计已到账金额(元)",
					key: "allReceivedPayAmount",
					minWidth: 100
				}
			]
		}
	},
	mounted() {
		this.getProjType()
	},
	methods: {
		handleProjectTypeChange(value) {
			console.log('选择的项目类型:', value, typeof value);
			
			const selectedType = this.projTypeList.find(item => item.id === value);
			console.log('找到的项目类型:', selectedType);
			
			this.isIITProject = selectedType && selectedType.typeName === "研究者发起的研究（IIT）";
			
			if (!this.isIITProject) {
				this.searchParams.iitType = "";
			}
			
			// 确保更新后立即触发重新渲染
			this.$nextTick(() => {
				this.searchHandle();
			});
		},
    async getProjType() {
      const res = await apiGetProjTypeList({ isDesigned: 1 })
      if (res) {
        const { data } = res
        this.projTypeList = data || []
        this.searchParams.projectTypeId = this.projTypeList.length
          ? this.projTypeList[1].id
          : ""
        this.oldSearchParams.projectTypeId = this.searchParams.projectTypeId
        
        if (this.searchParams.projectTypeId) {
          const selectedType = this.projTypeList.find(item => item.id === this.searchParams.projectTypeId);
          this.isIITProject = selectedType && selectedType.typeName === "研究者发起的研究（IIT）";
        }
      }
      this.initData()
    },
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetOutlayFirstListPage({
					...this.oldSearchParams,
					pageNum: this.page.pageNum,
					pageSize: this.page.pageSize,
				})
				if (res) {
          const { records, total } = res
          console.log('返回的数据:', records);
					this.data = records || []
					this.page.total = total
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.projectTypeId = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.pageNum = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.arrivalTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.arrivalTimeEnd = this.times.length ? this.times[1] : ""
			this.searchParams.iitType = this.searchParams.iitType
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.pageNum = 1
			this.$nextTick(() => {
				this.initData()
			})
		},
		getSponsorKey() {
			const projectTypeId = String(this.searchParams.projectTypeId);
			if (projectTypeId === "10013") {
				return "iitSponsorName";
			} else if (projectTypeId === "10011") {
				return "istSponsorName";
			}
			return "provideDate";
		}
	}
}
</script>

